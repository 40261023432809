import React from 'react';
import { Container, Button, Carousel, Image, Alert } from 'react-bootstrap';
import { useState } from 'react';
// import background from '../../assets/images/bgblue.jpg';
import backgroundwhite from '../../assets/images/homebannerbg.png';
import imgonebnr from '../../assets/images/bnr1.svg';
import imgtwobnr from '../../assets/images/landingpage/Invest_banner_clip.png';
import imgthreebnr from '../../assets/images/landingpage/Raise_banner_clip.png';
// import star from '../../assets/images/star.svg';
// import rocket from '../../assets/images/rocket.svg';
// import announce from '../../assets/images/megaphone.svg';
import { Link } from 'react-router-dom';

const HomepageBanner = () => {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <>
        <div className="mrgntp-hdr"></div>
        <Carousel className="arw-invi" fade>
          <Carousel.Item>
            <div
              className="bnnr-box bluebg-sqr colorbg-one relbox"
              style={{ backgroundImage: `url(${backgroundwhite})` }}
            >
              <Container style={{top: "16%", position: "relative"}}>
                <div className="d-flex relbox">
                  <div className="bnr-hd align-self-center py-3">
                    <h2 className="text-white font-weight-bold">
                      The Philippines’ First Equity Crowdfunding Platform
                    </h2>
                    <p className="text-white">
                      An end-to-end investor management or investment platform.<br/>
                      Pitch, promote, and reach out to potential fundraiser or<br/>
                      investors —all-in-one place!
                    </p>
                    <Link to="https://auth.v2.roundone.ph/registration">
                      <Button type="button" className="signbtn-bnr">
                        Sign Up
                      </Button>
                    </Link>
                  </div>
                  <div className="px-4 text-left rightbx-bnr vslebx" >
                    <Image src={imgonebnr} alt="" className="full-w"/>
                  </div>
                </div>
              </Container>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="bnnr-box bluebg-sqr colorbg-two relbox"
              style={{ backgroundImage: `url(${backgroundwhite})` }}
            >
              <Container style={{top: "5%", position: "relative"}}>
                <div className="d-flex">
                  <div className="bnr-hd align-self-center relbox py-3">
                    {/* <Image src={star} alt="" className="staricon" /> */}
                    <h2 className="text-white">
                      Your end-to-end<br/> 
                      Investment Platform
                    </h2>
                    <p className="text-white">
                      Access a wide range of investment opportunities where you<br/>
                      can browse, subscribe, manage and monitor your investments.<br/>
                    </p>
                    <Link to="/Invest">
                      <Button type="button" className="signbtn-bnr">
                        Invest
                      </Button>
                    </Link>
                  </div>
                  <div className="px-4 text-left rightbx-bnr vslebx">
                    <Image src={imgtwobnr} alt="" className="full-w" style={{height: '86vh', width: '60vw'}}/>
                  </div>
                </div>
              </Container>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="bnnr-box bluebg-sqr bfr-lightbgclr mrewhte-colorblur colorbg-three"
              style={{ backgroundImage: `url(${backgroundwhite})` }}
            >
              <Container className="relbox" style={{top: "5%", position: "relative"}}>
                <div className="d-flex">
                  <div className="bnr-hd align-self-center relbox py-3">
                    {/* <Image src={rocket} alt="" className="rocketicon" /> */}
                    <h2 className="text-white">
                      An Investor Relations Tool for Fundraisers
                    </h2>
                    <p className="text-white">
                      Enjoy the simplicity of pitching, promoting, investment<br/>
                      facilitation, managing investors feedback and updates.<br/>
                    </p>
                    <Link to="/Raise">
                      <Button type="button" className="signbtn-bnr">
                        Raise
                      </Button>
                    </Link>
                    {/* <Image src={announce} alt="" className="megaphoneicon" /> */}
                  </div>
                  <div className="px-4 text-left rightbx-bnr vslebx">
                    <Image src={imgthreebnr} alt="" className="full-w" style={{height: '86vh', width: '60vw'}} />
                  </div>
                </div>
              </Container>
            </div>
          </Carousel.Item>
        </Carousel>
       
      </>
    );
  }
};

export default HomepageBanner;
